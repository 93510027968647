import React, { useState,useEffect } from "react";
import ProfileInformation from "../../components/Profileinformation/ProfileInformation";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import "./Profile.css";
import ProfileIcon from "../../images/profile_icon.png";
import Delete from "../../images/delete.png";
import { Form, Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import ProfileModal from '../../components/ProfileModal'
import {ProfileDetails} from '../../Actions/userdetailsaction'
import {useDispatch,useSelector} from 'react-redux'
import Loader from '../../components/Loader'
import PasswordChange from '../PasswordReset/PasswordReset'
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


const Profile = () => {
  const [value, setValue] = useState(0);
  const [open,setOpen]=useState(false)
  const [company,setCompany]=useState(false)
  const dispatch = useDispatch()
  const {profiledata={},ploader=false}=useSelector(({userdetails})=> userdetails)
  console.log(profiledata)
  const handleClose =()=> {
      setOpen(false)
  }
  const handleCloseCompany =()=> {
    setCompany(false)
    }
  const handleChange = (event, value) => {
    setValue(value);
  };
  useEffect(() => {
    dispatch(ProfileDetails())
  }, [])
  if (open) {
      return <ProfileModal open={open} handleClose={handleClose} text={"Delete Admin"} alert={"Are You sure,You want to delete your admin and Make someone else as admin?"} buttonname={"Delete Admin"}/>
  }
  if (company) {
    return <ProfileModal open={company} handleClose={handleCloseCompany} text={"Delete Company"} alert={"Are You sure,You want to delete your company?"} buttonname={"Delete Company"}/>
    }
  return (
    <div className="main">
      {ploader?<Loader msg="Please wait we are retrieving your profile details" />:
      <div>
      <div id="productsHead" className="row">
        <h5>Profile</h5>
      </div>

      <div className="profiletabs">
        <div className="d-flex align-items-center">
          <img src={ProfileIcon} className="profile" />
          <div>
            <div>{profiledata.First_Name}</div>
            <div>{profiledata.email}</div>
          </div>
        </div>
        <div className="tabs">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="PROFILE INFORMATION" />
            <Tab label="SECURITY" />
          </Tabs>
          <hr className="tabshr"/>
          {value === 0 && <ProfileInformation profiledata={profiledata}/>}
          {value === 1 && <PasswordChange />}
        </div>
      </div>
      <div className="d-flex profiletabs deletediv">
        <h6 className="col-md-11 ">Delete Admin(Make someone else as admin)</h6>
        <img src={Delete} onClick={()=> setOpen(true)} style={{ height: "30px" }} className=" " />
      </div>
      <div className="d-flex profiletabs deletediv">
        <h6 className="col-md-11">Delete Company</h6>
        <img src={Delete} onClick={()=> setCompany(true)} style={{ height: "30px" }} />
      </div>
      <div>
      </div>
      </div>
  }
    </div>
  );
};

export default Profile;
