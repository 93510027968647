export const IND_ONE = "https://one.in-d.ai/api/";

export const PATH = {
  wrong_pass_return: "users/wrong_pass_return/",
  wrong_pass_update: "users/wrong_pass_update/",
  profiledetails: "users/get_user_details/",
  passwordexpiry: "users/pass_expiry_check/",
  profileupdate: "users/update_user_details/",
  changepassword: "change_password/",
  passwordreset: "password_reset/",
  passwordresetconfirm: "password_reset/confirm/",
  Registartion: "users/",
};
export { default as ActionType } from "./actionTypes";
