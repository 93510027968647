import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import Register from "./Views/register/register";
import Login from "./Views/login/login";
import Products from "./Views/products/products";
import Billing from "./Views/billing/billing";
import Users from "./Views/users/users";
import RegisterSuccess from "./Views/RegisterSuccess/register";
import RegisterError from "./Views/RegisterError/register";
import RegisterActivateAccount from "./Views/RegisterActivateAccount/register";
import Profile from "./Views/Profile/Profile";
import RouteCustomeLayout from "./RouteCustomLayout";
import AuthCustomLayout from "./AuthCustomLayout";
import { SnackbarProvider } from "notistack";
import PasswordReset from "./Views/PasswordReset/PasswordReset";
import "./App.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import SignupWithMicrosoft from "./components/SignupWithMicrosoft/SignupWithMicrosoft";
import "react-toastify/dist/ReactToastify.css";

const history = createBrowserHistory();
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#00716e",
      main: "#00716e",
      dark: "#00716e",
    },
    secondary: {
      light: "#00716e",
      main: "#00716e",
      dark: "#00716e",
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
      size: "small",
    },
  },

  overrides: {
    MuiIconButton: {
      sizeSmall: {
        marginLeft: 4,
        marginRight: 4,
        padding: 16,
      },
    },
  },
});
const AuthRoute = () => {};
function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            // hideProgressBar={false}
            // newestOnTop={false}
            // closeOnClick
            // rtl={false}
            // pauseOnFocusLoss
            // draggable
            // pauseOnHover
            // Transition="slide"
          />
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/Register" component={Register} />
              <Route
                exact
                path="/Register/success"
                component={RegisterSuccess}
              />
              <Route exact path="/Register/error" component={RegisterError} />
              <Route
                exact
                path="/Register/activate-acount"
                component={RegisterActivateAccount}
              />
              <Route exact path="/Login" component={Login} />
              <Route exact path="/Products" component={Products} />
              <Route exact path="/Billing" component={Billing} />
              <Route exact path="/Users" component={Users} />
              <Route exact path="/resetpassword" component={PasswordReset} />
              <RouteCustomeLayout exact path="/Profile" component={Profile} />
              <Route exact path="/thankyou" component={SignupWithMicrosoft} />
            </Switch>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
