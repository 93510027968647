import { ActionType, IND_ONE, PATH } from "../Constants";
import axios from "axios";
import { toast } from "react-toastify";
export const createFormHeader = (formdata, token) => {
  return {
    method: "POST",
    data: formdata,
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
};
export const createFormHeaderGET = (token) => {
  return {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
export const createJsonHeader = (token) => {
  return {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const ProfileDetails = () => async (dispatch, getState) => {
  const token = localStorage.getItem("access");
  try {
    dispatch({ type: ActionType.GET_PROFILE_DATA_REQUEST });
    const aadhardata = await axios(
      `${IND_ONE}${PATH.profiledetails}`,
      createFormHeaderGET(token)
    );
    console.log(aadhardata);
    if (aadhardata.status === 200) {
      dispatch({
        type: ActionType.GET_PROFILE_DATA_SUCCESS,
        payload: aadhardata.data.data,
      });
    } else {
      dispatch({ type: ActionType.GET_PROFILE_DATA_FAIL });
    }
  } catch (error) {
    dispatch({ type: ActionType.GET_PROFILE_DATA_FAIL });
  }
};
export const PasswordExpiry =
  (token, history, cb) => async (dispatch, getState) => {
    try {
      const aadhardata = await axios(
        `${IND_ONE}${PATH.passwordexpiry}`,
        createFormHeaderGET(token)
      );
      console.log(aadhardata);
      if (aadhardata.status === 200) {
        cb(false);
        dispatch({ type: ActionType.PASSWORD_AGING_SUCCESS });
        history.push("/Products");
      } else if (aadhardata.status === 400) {
        console.log(aadhardata);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data.data === "password expired or older than 3 months"
      ) {
        cb(true);
        dispatch({ type: ActionType.PASSWORD_AGING_FAILED });
      }
    }
  };
export const Logout = () => async (dispatch) => {
  dispatch({ type: ActionType.LOGOUT });
};
export const ProfileUpdate = (state, cb) => async (dispatch, getState) => {
  const token = localStorage.getItem("access");
  try {
    const aadhardata = await axios.post(
      `${IND_ONE}${PATH.profileupdate}`,
      state,
      createJsonHeader(token)
    );
    console.log(aadhardata);
    if (aadhardata.status === 200) {
      cb(true);
    } else if (aadhardata.status === 400) {
      console.log(aadhardata);
      cb(false);
    }
  } catch (error) {
    cb(false);
    console.log(error);
  }
};

export const PasswordUpdate = (state) => async (dispatch, getState) => {
  const token = localStorage.getItem("access");
  try {
    const aadhardata = await axios.post(
      `${IND_ONE}${PATH.changepassword}`,
      state,
      createJsonHeader(token)
    );
    console.log(aadhardata);
    if (aadhardata.status === 201) {
      toast.success("Successfully changed password!.");
    } else if (aadhardata.status === 400) {
      console.log(aadhardata);
    }
  } catch (error) {
    console.log(error);
    console.log(error.response);
    if (
      error.response &&
      error.response.data.old_password &&
      error.response.data.old_password[0] === "Wrong password."
    ) {
      toast.error(error.response.data.old_password[0]);
    } else if (
      error.response &&
      error.response.data.password &&
      error.response.data.password[0] ===
        "Password must be confirmed correctly."
    ) {
    }
  }
};

export const ForgetPassword = (state, cb) => async (dispatch, getState) => {
  console.log(state);
  const data = {
    email: state,
  };
  const token = localStorage.getItem("access");
  try {
    const aadhardata = await axios.post(
      `${IND_ONE}${PATH.passwordreset}`,
      data
    );
    console.log(aadhardata);
    if (aadhardata.status === 200) {
      dispatch({ type: "FORGET_EMAIL", payload: state });
      toast.success("Successfully Sent Email!... ");
      cb(true);
    } else if (aadhardata.status === 400) {
      console.log(aadhardata.response);
      cb(false);
    }
  } catch (error) {
    console.log(error.response.data.email[0]);
    if (error && error.response && error.response.data.email) {
      toast.error(error.response.data.email[0]);
    }
    cb(false);
  }
};
export const ConfirmeResetPassword =
  (state, passwordkey, history) => async (dispatch, getState) => {
    const data = {
      password: state.password,
      token: passwordkey,
    };
    try {
      const aadhardata = await axios(
        `https://one.in-d.ai/api/password_reset/confirm/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        }
      );
      console.log(aadhardata);
      if (aadhardata.status === 200) {
        dispatch({ type: "USER_PASSWORD", payload: state.password });
        toast.success("You have successfully changed password!..");
        dispatch(userLogin(state, history));
      }
    } catch (error) {
      if (error.response.status === 400 && error.response.data) {
        toast.error(error.response.data.password[0]);
      } else if (error.response.status === 404) {
        toast.error("Forget Password link is expired.Please try again!..");
      }
      console.log(error.response);
    }
  };

export const GettingCount = (state, cb) => async (dispatch, getState) => {
  const data1 = {
    email: state.email,
  };
  try {
    axios(`${IND_ONE}${PATH.wrong_pass_return}`, {
      method: "POST",
      data: data1,
    }).then((res) => {
      if (res.status === 200) {
        cb(res.data, true);
      }
      console.log(res);
    });
  } catch (error) {
    if (error && error.response) {
      cb(false);
      alert(error.response.data.message);
    }
  }
};
export const UpdateCount = (state, count, cb) => async (dispatch, getState) => {
  const inccount = count + 1;
  const data1 = {
    email: state.email,
    upd_count: inccount,
  };
  try {
    axios(`${IND_ONE}${PATH.wrong_pass_update}`, {
      method: "POST",
      data: data1,
    }).then((res) => {
      console.log(res);
    });
  } catch (error) {
    if (error && error.response) {
      alert(error.response.data.message);
    }
  }
};

export const userLogin =
  (state, history, cb, errorcallback) => async (dispatch, getState) => {
    const { forgetemail, userpassword } = getState().userdetails;
    const data = {
      email: forgetemail || state.email,
      password: userpassword || state.password,
    };
    try {
      const login = await axios.post(`${IND_ONE}${"users/token/"}`, data);
      console.log(login);
      if (login.status === 200) {
        console.log(login.data.access);
        localStorage.setItem("access", login.data.access);
        // if (state.password.length > 8) {
        //   history.push("/Products");
        // }
        history.push("/Products");

        cb(true);
      } else if (login.status === 400) {
        console.log(login);
        cb(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.detail === "Password is incorrect") {
          cb(true);
        } else if (error.response.status === 401) {
          cb(false);
          toast.error(error.response.data.detail);
        }
      }
    }
  };

export const Registration = async (values, cb) => {
  const { firstName, lastName, password, phoneNumber, email } = values;
  const data = {
    first_name: firstName,
    last_name: lastName,
    password: password,
    email: email,
    phone: phoneNumber,
  };

  try {
    const registration = await axios.post(
      `${IND_ONE}${PATH.Registartion}`,
      data
    );
    console.log(registration);
    if (registration.status === 201) {
      toast.success(registration.data.message);
      cb(false);
    } else {
      toast.error("Registration Failed! ,Try again after sometime.");
      cb(false);
    }
  } catch (error) {
    cb(false);
    console.log(error.response);
    if (error && error.response) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Registration Failed!..");
    }
  }
};

export const userLogout = () => async (dispatch, getState) => {
  dispatch({ type: "LOGOUT" });
};
