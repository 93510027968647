import { ActionType } from "../Constants";
const initialState = {
  ploader: false,
  passwordaging: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_PROFILE_DATA_REQUEST:
      return { ...state, ploader: true };
    case ActionType.GET_PROFILE_DATA_SUCCESS:
      return { ...state, ploader: false, profiledata: action.payload };
    case ActionType.GET_PROFILE_DATA_FAIL:
      return { ...state, ploader: false };
    case ActionType.PASSWORD_AGING_SUCCESS:
      return { ...state, passwordaging: false };
    case ActionType.PASSWORD_AGING_FAILED:
      return { ...state, passwordaging: true };
    case ActionType.FORGET_EMAIL:
      return { ...state, forgetemail: action.payload };
    case ActionType.USER_PASSWORD:
      return { ...state, userpassword: action.payload };
    case ActionType.LOGOUT:
      return initialState;
    default:
      return state;
  }
};
